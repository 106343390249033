// import { useEffect } from 'react';
import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
// import { useParallaxController } from 'react-scroll-parallax';
import { useIntl } from 'react-intl';
import { PageWrapper } from '../components/layout/PageWrapper';
import {
  SectionFoodCollectionIterable,
} from '../components/layout/sections/SectionFoodCollectionIterable';
import { HeroFoodCollection } from '../components/layout/sections/SectionHeroFoodCollection';
import {
  AdditionalTextBox,
  ContentBox,
  Heading,
  Subtitle,
} from '../components/misc/FoodCollectionHeroContent';
import { ButtonNormal } from '../components/ui/Buttons';
import { useDefaultLocale } from '../hooks/useDefaultLocale';
import { usePageLocale } from '../hooks/usePageLocale';

const FoodCollection = ({
  data: {
  datoCmsFoodCollection: {
    heroImage: { gatsbyImageData },
    heroImagePx1000: { heroImageOneKData },
    heroImagePx768: { heroImageTabletData },
    heroImagePx600: { heroImageMobileData },
    heroTitle,
    heroSubtitle,
    additionalText,
  },
  products: { productsNodes },
}
                        }) => {
  const directedImages = withArtDirection(getImage(gatsbyImageData), [
    {
      media: '(max-width: 600px)',
      image: getImage(heroImageMobileData),
    },
    {
      media: '(max-width: 768px)',
      image: getImage(heroImageTabletData),
    },
    {
      media: '(max-width: 1000px)',
      image: getImage(heroImageOneKData),
    },
  ]);
  // const parallaxController = useParallaxController();

  /*   useEffect(() => {
    window.requestAnimationFrame(() => {
      parallaxController.update();
    });
  }); */
  const locale = usePageLocale();
  const { defaultLocale } = useDefaultLocale();
  const intl = useIntl();

  return (
    <>
      <HeroFoodCollection
        maxHeight="1000px"
        $minHeightMobile="1100px"
        imageData={directedImages}
        $gatsbyImageMaxHeight="850px"
        clipHeight="850px"
        contentBox={
        // eslint-disable-next-line react/jsx-wrap-multilines
          <ContentBox>
            <Heading>{heroTitle}</Heading>
            <Subtitle>{heroSubtitle}</Subtitle>
            <ButtonNormal
              $buttonGold
              buttonLink={locale === defaultLocale ? '/store-finder' : `/${locale}/store-finder`}
              buttonText={intl.formatMessage({ id: 'storeFind' })}
              $bolder
            />
            <AdditionalTextBox>{additionalText}</AdditionalTextBox>
          </ContentBox>
      }
      />

      {productsNodes.map(
      (
        {
          originalId,
          productTitle,
          productDescription,
          sectionImage: { wideImageData },
          image: { productImageData },
          magicWord,
          linkProdotto,
        },
        index
      ) => (
        <SectionFoodCollectionIterable
          key={originalId}
          productRecordId={linkProdotto?.originalId}
          productTitle={productTitle}
          productDescription={productDescription}
          wideImageData={wideImageData}
          productImageData={productImageData}
          magicWord={magicWord}
          waveIndex={index}
          indexLength={productsNodes.length}
        />
      )
    )}
    </>
);
};

const FoodCollectionPage = ({
  data,
  pageContext,
}) => {
  const {
    datoCmsFoodCollection: { seo }
  } = data;
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <FoodCollection data={data} />
    </PageWrapper>
  );
};

export default FoodCollectionPage;

export const query = graphql`
  query FoodCollectionQuery($locale: String!) {
    datoCmsFoodCollection(locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      heroImage {
        gatsbyImageData
      }
      heroImagePx1000 {
        heroImageOneKData: gatsbyImageData
      }
      heroImagePx768 {
        heroImageTabletData: gatsbyImageData
      }
      heroImagePx600 {
        heroImageMobileData: gatsbyImageData
      }
      heroTitle
      heroSubtitle
      additionalText
    }
    products: allDatoCmsFoodCollectionProduct(
      sort: { fields: position }
      filter: { locale: { eq: $locale } }
    ) {
      productsNodes: nodes {
        originalId
        productTitle
        productDescription
        magicWord
        linkProdotto {
          ... on DatoCmsHeroProduct {
            originalId
            productCategory {
              slug
            }
            slug
          }
          ... on DatoCmsProdotti {
            originalId
            productCategory {
              slug
            }
            slug
          }
        }
        sectionImage {
          wideImageData: gatsbyImageData
        }
        image {
          productImageData: gatsbyImageData
        }
      }
    }
  }
`;
