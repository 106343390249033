import styled from 'styled-components';

export const ContentBox = styled.section`
  display: grid;
  row-gap: var(--gap-5);
  max-width: 500px;
`;

export const AdditionalTextBox = styled.p`
  padding: var(--gap-7) var(--gap-6);
  color: var(--gold-2);
  border: 2px solid var(--gold-2);
  font-size: var(--font-size-micro);
  font-weight: 700;
  border-radius: var(--gap-6);
  max-width: 300px;
`;

export const Heading = styled.h1`
  color: var(--gold-2);
  font-weight: 700;
  font-size: var(--font-size-6);
  line-height: 1;
  text-align: left;
  max-width: 500px;
  line-height: 1.3;

  @media screen and (max-width: 810px) {
    font-size: var(--font-size-5);
  }

  @media screen and (max-width: 370px) {
    font-size: var(--font-size-4);
  }
`;

export const Subtitle = styled.p`
  font-size: var(--font-size-2);
  font-weight: 500;
  line-height: 1.6;
  max-width: 500px;
  color: var(--blue-2);

  @media screen and (max-width: 470px) {
    font-size: var(--font-size);
  }
`;
