import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
// import { Parallax } from 'react-scroll-parallax';

const ClipPath = () => (
  <svg width="0" height="0" className="foodCollectionClipPathShape">
    <defs>
      <clipPath id="mask1" clipPathUnits="objectBoundingBox">
        <path d="M0,0 V0.803 C0.127,0.925,0.304,1,0.5,1 s0.373,-0.075,0.5,-0.197 V0" />
      </clipPath>
    </defs>
  </svg>
);

const ClipPathBorder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1800"
    height="1075"
    viewBox="0 0 1800 1075"
    preserveAspectRatio="none"
    className="foodCollectionClipPath"
  >
    <g>
      <path
        fill="var(--gold-2)"
        d="M0 853.7v10C227.866 994.009 546.878 1075 900 1075s672.134-80.991 900-211.3v-10C1572.134 984.009 1253.122 1065 900 1065S227.866 984.009 0 853.7"
      />
      <path fill="none" d="M0 0H1800V853.7H0z" />
    </g>
  </svg>
);

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 3;
  background: ${({ clipBackground }) => clipBackground};
`;

const HeroImage = styled(GatsbyImage)`
  width: 100%;
  max-height: ${({ $gatsbyImageMaxHeight }) =>
    $gatsbyImageMaxHeight || '600px'};
  min-height: 400px;
  clip-path: url(#mask1);

  @media screen and (max-width: 1000px) {
    min-height: ${({ $minHeightMobile }) => $minHeightMobile};
  }

  @media screen and (max-width: 600px) {
    min-height: 900px;
  }

  @media screen and (max-width: 600px) {
    min-height: 1000px;
  }
`;

const HeroContentBox = styled.div`
  width: 1240px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0 var(--padding);

  @media screen and (max-width: 1000px) {
    align-items: flex-end;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 180px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 60px;
    border-bottom: 4px solid var(--gold-2);
  }

  & svg {
    margin-top: -15%;
    margin-right: 10%;
    transform: scale(0.8);
    transform-origin: right;

    @media screen and (max-width: 1100px) {
      margin-top: -25%;
    }

    @media screen and (max-width: 800px) {
      margin-top: -40%;
      margin-right: 0 !important;
      transform: scale(0.5);
    }
  }

  @media screen and (max-width: 1240px) {
    width: 100%;
  }
`;

export const HeroFoodCollection = ({
  imageData,
  contentBox,
  maxHeight,
  $minHeightMobile,
  $gatsbyImageMaxHeight,
  clipHeight,
}) => {
  return (
    <HeroWrapper maxHeight={maxHeight}>
      <ClipPath />
      <HeroContentBox>{contentBox}</HeroContentBox>
      {/*       <Parallax className="parallaxHeroFoodCollection" translateY={[-25, 25]}> */}
      <HeroImage
        $minHeightMobile={$minHeightMobile}
        $gatsbyImageMaxHeight={$gatsbyImageMaxHeight}
        loading="eager"
        alt=""
        image={imageData}
      />
      {/*       </Parallax> */}
      <ClipPathBorder clipHeight={clipHeight} />
    </HeroWrapper>
  );
};
