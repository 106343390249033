// import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
// import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';
import { HeadingSection, SubtitleSection } from '../../styles/Typography';
import { NavigatorButtonNormal } from '../../ui/Buttons';
import { SvgWaveBottom, SvgWaveTop } from '../../vectors/Waves';
import { isOdd } from '../../../utils/misc';
import { easeInOutSine, slideInAnim } from '../../styles/animations';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
`;

const ContentOverlayWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--padding);
  background: white;
  position: relative;
  z-index: 5;
`;

const ContentContainer = styled.div`
  width: 1180px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  z-index: 3;
  position: relative;
  background: white;
  column-gap: ${({ waveIndex }) => (isOdd(waveIndex) ? '0px' : '100px')};

  @media screen and (max-width: 1140px) {
    column-gap: 40px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    column-gap: 40px;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    row-gap: var(--gap-4);
  }
`;

const WideImageWrapper = styled.div`
  z-index: ${({ waveIndex, indexLength }) =>
    waveIndex === indexLength - 1 ? 0 : 1};
  position: relative;
  width: 100%;
  margin-bottom: ${({ waveIndex, indexLength }) =>
    waveIndex === indexLength - 1 && '-180px !important'};
`;

const WideImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 600px;

  @media screen and (max-width: 640px) {
    min-height: 500px;
  }
`;

const ProductImageContainer = styled.div`
  opacity: 0;
  position: relative;
  z-index: 0;
  ${({ inView }) =>
    inView &&
    css`
      animation: ${slideInAnim} 600ms ${easeInOutSine} forwards 200ms;
    `}
`;

const TextContainer = styled.div`
  display: grid;
  row-gap: var(--gap-4);
  height: min-content;

  ${({ waveIndex }) =>
    isOdd(waveIndex) &&
    css`
      grid-row: 1;
    `}

  @media screen and (max-width: 640px) {
    grid-row: unset;
    row-gap: var(--gap-6);
  }
`;

const MagicWord = styled.span`
  color: var(--gold-2);
  font-weight: 700;
  display: block;
`;

export const SectionFoodCollectionIterable = ({
  productTitle,
  productDescription,
  productImageData,
  wideImageData,
  magicWord,
  waveIndex,
  indexLength,
  productRecordId,
}) => {
  const intl = useIntl();

  // const parallaxController = useParallaxController();

  /*   useEffect(() => {
    window.requestAnimationFrame(() => {
      parallaxController.update();
    });
  }); */

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '20px 0px',
  });

  return (
    <Wrapper ref={ref}>
      <ContentOverlayWrapper>
        <ContentContainer waveIndex={waveIndex}>
          <ProductImageContainer inView={inView}>
            <GatsbyImage alt={productTitle} image={productImageData} />
          </ProductImageContainer>
          <TextContainer waveIndex={waveIndex}>
            <HeadingSection blueHeading>{productTitle}</HeadingSection>
            <SubtitleSection>
              {productDescription}
              <MagicWord>{magicWord}</MagicWord>
            </SubtitleSection>
            {productRecordId && (
              <NavigatorButtonNormal
                buttonText={intl.formatMessage({ id: 'discoverMore' })}
                recordId={productRecordId}
                $bolder
                $buttonGold
              />
            )}
          </TextContainer>
        </ContentContainer>
      </ContentOverlayWrapper>
      <WideImageWrapper waveIndex={waveIndex} indexLength={indexLength}>
        <SvgWaveTop absolute waveFill="var(--background-white)" />
        {waveIndex === indexLength - 1 ? null : (
          <SvgWaveBottom
            reverse
            waveFill="var(--background-white)"
            waveIndex={waveIndex}
          />
        )}
        {/* <Parallax translateY={[-25, 25]}> */}
        <WideImage alt="" image={wideImageData} />
        {/* <</Parallax> */}
      </WideImageWrapper>
    </Wrapper>
  );
};
